import GraphView from './graphView.vue';
import axios from '../../common/axios.js'

export default {
	computed:{
		params(){
			if(this.$store.state.entreprises.graphs.EntreprisesParSecteur == undefined){
				return null;
			}else{
				//RZ--> si les données de graphes sont vide on masque le graphique
				if (Object.keys(this.$store.state.entreprises.graphs.EntreprisesParSecteur.stats).length === 0) return null;
			 	
				let data = [];
			 	let max = 21

			 	_.forEach(this.$store.state.entreprises.graphs.EntreprisesParSecteur.stats, function(stat, i){
			 		if(i < max){
			 			let _data = {
			 				titre: 		stat.titre,
							code: 		stat.nace,
							y: 			stat.nbr,
							name: 		stat.titre,
							pourcent: 	stat.pourcent
				 		}
				 		data.push(_data);
			 		}
			 	});
			 	return {
			 		chart: {
			 			type: 'column',
			 			height: 700,
			 			marginLeft:90,
			 			marginRight:30,
			 		},
			 		credits: {
			 			enabled: false
			 		},
			 		title: {
			 			text: this.$t('graph_titre_entreprise_par_secteur')
			 		},
			 		legend: {
			 			enabled: false
			 		},
			 		tooltip: {
			 			pointFormat: '{point.code:.2f}<br/>{point.y} ' + this.$t('graph_entreprises') + ' / <b>{point.pourcent:.1f}%</b>'
			 		},
			 		plotOptions: {
			 			pie: {
			 				allowPointSelect: true,
			 				cursor: 'pointer'
			 			}
			 		},
			 		xAxis: {
			 			type: 'category',
			 			labels: {
			 				rotation: -60
			 			}
			 		},
			 		yAxis: {
			 			min: 0,
			 			title: {
			 				text: this.$t('graph_entreprises') + '<br />&nbsp;'
			 			}
			 		},
			 		series: [{
			 			name: this.$t('graph_entreprises'),
			 			colorByPoint: true,
			 			data: data,
			 			dataLabels: {
			 				enabled: true,
			 				// rotation: -90,
			 				// color: '#FFFFFF',
			 				align: 'center',
			 				format: '{point.y}',
			 				// y: 10 // 10 pixels down from the top
			 			}
			 		}]
			 	}
			}
		}
	},
	methods: {
		
	},
	components: {
		GraphView
	}
}