import _ from 'lodash'
import {Chart} from 'highcharts-vue'
import Highcharts from "highcharts";
import exportingInit from "highcharts/modules/exporting";
import offlineExporting from "highcharts/modules/offline-exporting";

exportingInit(Highcharts);
offlineExporting(Highcharts);

export default {
	props: ['chartOptions'],
	methods: {
		getChartOptions(){
			let exportOptions = {
				exporting: {
					menuItemDefinitions: {
						viewFullscreen: {
							text: this.$t('bouton_graphique_viewFullscreen')
						},
						printChart: {
							text: this.$t('bouton_graphique_printChart')
						},
						downloadPNG: {
							text: this.$t('bouton_graphique_downloadPNG')
						},
						downloadJPEG: {
							text: this.$t('bouton_graphique_downloadJPEG')
						},
						downloadPDF: {
							text: this.$t('bouton_graphique_downloadPDF')
						},
						downloadSVG: {
							text: this.$t('bouton_graphique_downloadSVG')
						}
					}
				}
			}
			let options = this.chartOptions
			let new_options = {...options, ...exportOptions}

			let styleOptions = {
				chart: {
					style: {
						fontFamily : 'Cera',
						color: '#717579'
					}
				},
				title: {
					style: {
						fontWeight: 'bold',
						color: '#202124'
					}
				},
				xAxis: {
					labels: {
						style: {
							color: '#717579'
						}
					}
				},
				yAxis: {
					labels: {
						style: {
							color: '#717579'
						}
					}
				},
				colors: [
					'#07CE6F',
					'#3861FB',
					'#047B42',
					'#FF8642',
					'#717579',
					'#BF3081',
					'#0E1C36',
					'#07C2CE',
					'#3A07CE',
					'#7607CE',
					'#CE5A07',
					'#286347',
					'#BECE07',
					'#76ECB4',
					'#FCB086',
					'#2055B9',
					'#32DA7F',
					'#ED65B2',
					'#F45B5B',
					'#2FA399',
				]
			}

			new_options = _.merge(new_options, styleOptions)

			//AS--> Vérifie s'il faut faire passer une valeur "Autre" en gris
			new_options = this.checkAutre(new_options)

			return new_options
		},
		checkAutre(options){
			let self = this

			if(options.series && typeof options.series == 'object' && options.series.length){
				options.series.forEach(serie => {
					if(serie.data){
						serie.data.forEach(_data => {
							if(_data.titre && ["Autre", "Autres", "Other", "Others"].includes(_data.titre)) _data.color = '#DADCE0'
						})
					}
				})
			}

			return options
		}
	},
	components: {
		highcharts: Chart 
	}
}