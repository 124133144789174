import GraphView from './graphView.vue';
import axios from '../../common/axios.js'

export default {
	computed:{
		params(){
			if(this.$store.state.entreprises.graphs.CaracteristiquesParCategorie == undefined){
				return null;
			}else{
				//RZ--> si les données de graphes sont vide on masque le graphique
				if (Object.keys(this.$store.state.entreprises.graphs.CaracteristiquesParCategorie.stats).length === 0) return null;

			 	let data = [];

				_.forEach(this.$store.state.entreprises.graphs.CaracteristiquesParCategorie.stats, function(stat){
					data.push({
						titre: 			stat.titre,
						name: 			stat.nom,
						y: 				stat.pourcent_renseignes | 0,
						nbr_dechets: 	stat.nbr_dechets,
						nbr_renseignes: stat.nbr_renseignes,
						percent: 		stat.pourcent_renseignes
					});
				});
				return {
					chart: {
						type: 'column'
					},
				    credits: {
						enabled: false
					},
					title: {
						text: this.$t('graph_informations_caracteristiques_pour')
					},
					legend: {
						enabled: false
					},
				    tooltip: {
				        pointFormat: '{point.nbr_renseignes} ' + this.$t('graph_renseignes') + ' / {point.nbr_dechets}<br/>{point.percent:.1f}%</b>'
				    },
				    plotOptions: {
				        pie: {
				            allowPointSelect: true,
				            cursor: 'pointer'
				        }
				    },
					xAxis: {
						type: 'category',
						labels: {
							rotation: -45
						}
					},
					yAxis: {
						min: 0,
						max: 100,
						title: {
							text: '% ' + this.$t('graph_renseignes')
						}
					},
					series: [{
						name: '% ' + this.$t('graph_renseignes'),
						colorByPoint: true,
						data: data,
				        dataLabels: {
							enabled: true,
							format: '{point.percent}%'
						}
					}]
				}
			}
		}

	},
	components: {
		GraphView
	}
}