import GraphView from './graphView.vue';
import axios from '../../common/axios.js'

export default {
	computed:{
		params(){
			if(this.$store.state.entreprises.graphs.EntreprisesInformations == undefined){
				return null;
			}else{
				//RZ--> si les données de graphes sont vide on masque le graphique
				if (Object.keys(this.$store.state.entreprises.graphs.EntreprisesInformations.stats).length === 0) return null;

				let stats = this.$store.state.entreprises.graphs.EntreprisesInformations.stats
			 	let data = [
					{
						name: 		this.$t('graph_adresse'),
						y: 			stats.nbr_adresse,
						percent: 	stats.nbr_adresse_percent
					},{
						name: 		this.$t('graphe_nace'),
						y: 			stats.nbr_nace,
						percent: 	stats.nbr_nace_percent
					},{
						name: 		this.$t('graph_nbr_salaries'),
						y: 			stats.nbr_nbr_salaries,
						percent: 	stats.nbr_nbr_salaries_percent
					},{
						name: 		this.$t('graph_ca'),
						y: 			stats.nbr_billing_data,
						percent: 	stats.nbr_billing_data_percent
					},{
						name: 		this.$t('graph_email'),
						y: 			stats.nbr_email,
						percent: 	stats.nbr_email_percent
					},{
						name: 		this.$t('graph_tel'),
						y: 			stats.nbr_tel,
						percent: 	stats.nbr_tel_percent
					}
				];
				return {
					chart: {
				        type: 'column'
				    },
				    credits: {
						enabled: false
					},
				    title: {
				        text: this.$t('graph_titre_presence_informations')
				    },
					legend: {
						enabled: false
					},
				    tooltip: {
				        pointFormat: '{point.y} ' + this.$t('graph_entreprises') + ' / ' + this.$store.state.entreprises.graphs.EntreprisesInformations.nbr_entreprises + '<br/>{point.percent:.2f}%</b>'
				    },
				    plotOptions: {
				        pie: {
				            allowPointSelect: true,
				            cursor: 'pointer'
				        }
				    },
					xAxis: {
						type: 'category',
						labels: {
							rotation: -45
						}
					},
					yAxis: {
						min: 0,
						title: {
							text: this.$t('graph_nbr_entreprises')
						}
					},
					series: [{
						name: this.$t('graph_nbr_entreprises'),
						colorByPoint: true,
						data: data,
				        dataLabels: {
							enabled: true,
							format: '{point.percent}%'
						}
					}]
				}
			}
		}
	},
	components: {
		GraphView
	}
}