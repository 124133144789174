import GraphView from './graphView.vue';
import axios from '../../common/axios.js'

export default {
	computed:{
		params(){
			let self = this
			if(this.$store.state.entreprises.graphs.TonnageParSecteur == undefined){
				return null;
			}else{
				//RZ--> si les données de graphes sont vide on masque le graphique
				if (Object.keys(this.$store.state.entreprises.graphs.TonnageParSecteur.stats).length === 0) return null;

				let data = [];
				_.forEach(this.$store.state.entreprises.graphs.TonnageParSecteur.stats, function(stat){
					data.push({
						name:	'<span style="font-weight:700">' + (stat.nace ? stat.nace + ' - ' : '') + _.truncate(stat.titre, {'length': 50}) + '</span><br />'
								+'<span style="font-weight:normal">'+self.formatNumber(stat.tonnage)+' t / </span>'
							 	+'<span style="font-weight:normal">'+self.formatNumber(stat.pourcent)+'%</span>',
						y: 		stat.tonnage,
						titre: 	stat.titre,
						nace:   stat.nace
					});
				});
				return {
					chart: {
						plotBackgroundColor: null,
						plotBorderWidth: null,
						plotShadow: false,
						type: 'pie'
					},
					credits: {
						enabled: false
					},
					title: {
						text: this.$t('graph_tonnages') + ' ' + this.$t('graph_par_secteur')
					},
					tooltip: {
						formatter: function (point) {
							return '<b>' + (this.point.nace ? this.point.nace + ' - ' : '') + this.point.titre + '</b><br/>'+ self.formatNumber(this.point.y) + ' t / '+Math.round(this.percentage*10)/10+'%'
						},
					},
					plotOptions: {
						pie: {
							allowPointSelect: true,
							cursor: 'pointer'
						}
					},
					series: [{
						name: 'Tonnes',
						colorByPoint: true,
						data: data
					}]
				}
			}
		}
	},
	methods: {
		formatNumber(number){
			let options = {
				maximumFractionDigits: 1
			}
			return new Intl.NumberFormat("fr-FR", options).format(number)
		}

	},
	components: {
		GraphView
	}
}