import GraphEntreprisesSecteurs from './graphs/graph_entreprises_secteurs.vue';
import GraphTonnagesSecteur from './graphs/graph_tonnages_secteurs.vue';
import GraphTonnagesInterface from './graphs/graph_tonnages_interfaces.vue';
import GraphInformations from './graphs/graph_informations.vue';
import GraphInfosCaracteristiques from './graphs/graph_infos_caracteristiques.vue';
import GraphPertinences from './graphs/graph_pertinences.vue';
import GraphAssolement from './graphs/graph_assolement.vue';
import GraphMethanogenicPotentialPerInterface from './graphs/graph_methanogenic_potential_per_interface.vue';
import GraphMethanogenicPotentialPerInterfaceDetail from './graphs/graph_methanogenic_potential_per_interface_detail.vue';
import TonnageParInterfaceDetail from './graphs/graph_tonnage_par_interface_detail.vue';
import GraphStatuts from './graphs/graph_statuts.vue';

export default {
	created(){
		//AS--> Reset et recharge les graphs principaux si la vue intègre les statuts
		if(this.$store.getters.canUseStatuses){
			this.$store.commit('ENTREPRISES_GRAPHS', null);
			this.$store.dispatch('getGraphsAjax');
		}
	},
	methods: {
		close_filter(){
			this.$store.commit('UI_SET_CONTENT', {content: 'result'});
		}
	},
	computed: {
		graphLoaded(){
			let hasGraphsGeneric    = this.$store.state.entreprises.graphs && this.$store.state.entreprises.graphs.length !== 0
			let hasGraphsAssolement = !this.$store.state.ui.sourcing.graph_assolement || (this.$store.state.entreprises.graphs_assolement && this.$store.state.entreprises.graphs_assolement.length !== 0)

			//AS--> Si les données de graphs sont déjà récupérées on les renvoie directement
			if(hasGraphsGeneric && hasGraphsAssolement) return true;

			//AS--> Calcul des données de graphs génériques
			if(!hasGraphsGeneric) this.$store.dispatch('getGraphsAjax');

			//AS--> Calcul des données de graphs d'assolement
			if(!hasGraphsAssolement) this.$store.dispatch('getGraphsAssolementAjax');
			return false;
		}
	},
	components: {
		GraphEntreprisesSecteurs,
		GraphTonnagesSecteur,
		GraphTonnagesInterface,
		GraphInformations,
		GraphInfosCaracteristiques,
		GraphPertinences,
		GraphAssolement,
		GraphMethanogenicPotentialPerInterface,
		GraphMethanogenicPotentialPerInterfaceDetail,
		TonnageParInterfaceDetail,
		GraphStatuts,
	}
}