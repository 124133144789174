import GraphView from './graphView.vue';

export default {
	computed:{
		params(){
			if(this.$store.state.entreprises.graphs.statuses == undefined){
				return null;
			}else{
				//RZ--> si les données de graphes sont vide on masque le graphique
				if (Object.keys(this.$store.state.entreprises.graphs.statuses.stats).length === 0) return null;
			 	
				let data = [];

				this.$store.getters.getStatuses.forEach(status => {
					_.forEach(this.$store.state.entreprises.graphs.statuses.stats, function(stat, i){
						if(stat.id == status.slug){
							data.push({
								titre: 		status.name,
								code: 		stat.id,
								y: 			stat.nbr,
								name: 		status.name,
								color: 		status.color
							})
						}
					})
				})

			 	return {
			 		chart: {
			 			type: 'column',
			 			marginLeft:90,
			 			marginRight:30,
			 		},
			 		credits: {
			 			enabled: false
			 		},
			 		title: {
			 			text: this.$t('graph_titre_statuts')
			 		},
			 		legend: {
			 			enabled: false
			 		},
			 		tooltip: {
			 			// pointFormat: '{point.code:.2f}<br/>{point.y} ' + this.$t('graph_entreprises') + ' / <b>{point.pourcent:.1f}%</b>'
						pointFormat: '{point.y} ' + this.$t('graph_statuts_lead')
			 		},
			 		plotOptions: {
			 			pie: {
			 				allowPointSelect: true,
			 				cursor: 'pointer'
			 			}
			 		},
			 		xAxis: {
			 			type: 'category',
			 			labels: {
							rotation: -60
						}
					},
					yAxis: {
						min: 0,
						allowDecimals: false,
			 			title: {
			 				text: this.$t('graph_statuts_nbr_lead') + '<br />&nbsp;'
			 			}
			 		},
			 		series: [{
			 			name: this.$t('graph_entreprises'),
			 			colorByPoint: true,
			 			data: data,
			 			dataLabels: {
			 				enabled: true,
			 				align: 'center',
			 				format: '{point.y}',
			 			}
			 		}]
			 	}
			}
		}
	},
	methods: {},
	components: {
		GraphView
	}
}