import GraphView from './graphView.vue';
import axios from '../../common/axios.js'

export default {
	computed:{
		params(){
			let self = this
			if(this.$store.state.entreprises.graphs.TonnageParInterface == undefined){
				return null;
			}else{
				//RZ--> si les données de graphes sont vide on masque le graphique
				if (Object.keys(this.$store.state.entreprises.graphs.TonnageParInterface.stats).length === 0) return null;

				let data = [];
				// let drilldown = {series: []};

				_.forEach(this.$store.state.entreprises.graphs.TonnageParInterface.stats, function(stat){
					data.push({
						titre: 		stat.titre,
						name:		'<span style="font-weight:700">' + _.truncate(stat.titre, {'length': 50}) + '</span><br />'
									+'<span style="font-weight:normal">'+self.formatNumber(stat.tonnage)+' t / </span>'
							 		+'<span style="font-weight:normal">'+self.formatNumber(stat.pourcent)+'%</span>',
						y: 			stat.tonnage | 0,
						drilldown: 	stat.id,
						color : 	self.$store.getters.getGraphCouleursDechets('couleurs_dechets', stat.id)
					});
					if(typeof stat.childs != 'undefined'){
						let data_drilldown = [];
						_.forEach(stat.childs, function(_stat){
							data_drilldown.push([_stat.titre, _stat.tonnage | 0])
						});
						// drilldown.series.push({
						// 	name: stat.titre,
						// 	id: stat.id,
						// 	data: _.clone(data_drilldown)
						// });
					}
				});
				return {
					chart: {
						type: 'pie'
					},
					credits: {
						enabled: false
					},
					title: {
						text: this.$t('graph_tonnages') + ' ' + this.$t('graph_par_dechet')
					},
					tooltip: {
						formatter: function (point) {
							return '<b>' + this.point.titre + '</b><br/>'+ self.formatNumber(this.point.y) + ' t / '+Math.round(this.percentage*10)/10+'%'
						},
					},
					plotOptions: {
						series: {
							dataLabels: {
								enabled: true,
								format: '{point.name}'
							}
						}
					},
					series: [{
						name: this.$t('graph_tonnes'),
						colorByPoint: true,
						data: _.clone(data)
					}],
					// drilldown: _.clone(drilldown)
				}
			}
		}

	},
	methods: {
		formatNumber(number){
			let options = {
				maximumFractionDigits: 1
			}
			return new Intl.NumberFormat("fr-FR", options).format(number)
		}

	},
	components: {
		GraphView
	}
}