import GraphView from './graphView.vue';
import axios from '../../common/axios.js'

export default {
	computed:{
		params(){
			if(this.$store.state.entreprises.graphs.NiveauxPertinence == undefined){
				return null;
			}else{
				//RZ--> si les données de graphes sont vide on masque le graphique
				if (Object.keys(this.$store.state.entreprises.graphs.NiveauxPertinence.stats).length === 0) return null;

			 	let data = [];
			 	let self = this;
				_.forEach(this.$store.state.entreprises.graphs.NiveauxPertinence.stats, function(stat) {
					data.push({
						titre: 	stat.titre,
						name: 	self.$t('graph_label_ip_' + stat.ip),
						y: 		stat.pourcent,
						nbr: 	stat.nbr,
						label: 	self.$t('ip_label_' + stat.ip).replace(/\n/g, '<br>')
					})
				})
				return {
					chart: {
						type: 'column'
					},
					credits: {
						enabled: false
					},
					title: {
						text: this.$t('graph_titre_ip')
					},
					legend: {
						enabled: false
					},
					tooltip: {
						pointFormat: '{point.y:.1f}%<br/>{point.nbr} / ' + this.$store.state.entreprises.graphs.NiveauxPertinence.total + '<br/>{point.label}'
					},
					plotOptions: {
						pie: {
							allowPointSelect: true,
							cursor: 'pointer'
						}
					},
					xAxis: {
						type: 'category',
						labels: {
							rotation: -45
						}
					},
					yAxis: {
						min: 0,
						max: 100,
						title: {
							text: '%'
						}
					},
					series: [{
						name: '%',
						colorByPoint: true,
						data: data,
						dataLabels: {
							enabled: true,
							format: '{point.y:.1f}%'
						}
					}]
				}
			}
		}
	},
	components: {
		GraphView
	}
}